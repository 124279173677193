(function() {
    "use strict";

    var NS = "cmp";
    var IS = "carousel";

    var selectors = {
        self: "[data-" +  NS + '-is="' + IS + '"]'
    };

    var properties = {
        "multi": {
            "default": false,
            "transform": function(value) {
                return !(value === null || typeof value === "undefined");
            }
        },
        "multiNumber": {
            "default": 1.0,
            "transform": function(value) {
                value = parseFloat(value);
                return !isNaN(value) ? value : null;
            }
        },
        "pagination": {
            "default": true,
            "transform": function(value) {
                return !(value === null || typeof value === "undefined");
            }
        },
        "nextPreview": {
            "default": true,
            "transform": function(value) {
                return !(value === null || typeof value === "undefined");
            }
        },
        "autoplay": {
            "default": false,
            "transform": function(value) {
                return !(value === null || typeof value === "undefined");
            }
        },
        "delay": {
            "default": 5000,
            "transform": function(value) {
                value = parseFloat(value);
                return !isNaN(value) ? value : null;
            }
        },
        "autopauseDisabled": {
            "default": false,
            "transform": function(value) {
                return !(value === null || typeof value === "undefined");
            }
        }
    };

    function Carousel(config) {
        var that = this;

        var slidesNumber = [
            {
                type : "common-carousel",
                screen : [
                    {
                        size: 480,
                        number:1
                    },
                    {
                        size: 768,
                        number:2
                    },
                    {
                        size: 992,
                        number:3
                    }
                ]
            },
            {
                type : "news-carousel",
                screen : [
                    {
                        size: 0,
                        number:1
                    },
                    {
                        size: 768,
                        number:2
                    },
                    {
                        size: 992,
                        number:3
                    }
                ]
            },
            {
                type : "products-carousel",
                screen : [
                    {
                        size: 0,
                        number:1
                    },
                    {
                        size: 425,
                        number:2
                    },
                    {
                        size: 480,
                        number:3
                    },
                    {
                        size: 768,
                        number:4
                    },
                    {
                        size: 1024,
                        number:5
                    }
                ]
            },
            {
                type : "press-carousel",
                screen : [
                    {
                        size: 0,
                        number:1
                    },
                    {
                        size: 640,
                        number:2
                    },
                    {
                        size: 1024,
                        number:3
                    },
                    {
                        size: 1200,
                        number:4
                    }
                ]
            }
        ]

        if (config && config.element) {
            init(config,slidesNumber);

        }

        /**
         * Initializes the Carousel
         *
         * @private
         * @param {CarouselConfig} config The Carousel configuration
         */
        function init(config,slidesNumber) {
            // prevents multiple initialization
            config.element.removeAttribute("data-" + NS + "-is");


            let carouselIndexTag = "data-" + NS + "-is-"+String(Math.random()).replace('0.','');
            $(config.element.parentElement).addClass(carouselIndexTag)

            setupProperties(config.options);
            cacheElements(config.element);

            let carouselFlag = config.element.parentElement.classList[1]; //fix pagination bug
            let width = window.innerWidth;
            let sw = new Swiper (config.element, {
                //loop: true, // 循环模式选项
                pagination : {
                    el: that._properties.pagination ? "." + carouselIndexTag + " .swiper-pagination" : '',
                    clickable :true,
                },
                navigation:{
                    nextEl: that._properties.nextPreview ? "." + carouselIndexTag + ' .swiper-button-next' : null,
                    prevEl: that._properties.nextPreview ? "." + carouselIndexTag + ' .swiper-button-prev' : null,
                    disabledClass: 'swiper-button-disabled',
                },
                autoplay: that._properties.autoplay ? {
                    delay: that._properties.delay
                } : false,
                slidesPerView: that._properties.multi ? getSlidesNumber(width,carouselFlag) : 1,
                slidesPerGroup: that._properties.multi ? getSlidesNumber(width,carouselFlag) : 1,
                on:{
                    resize:function(){
                        let width = window.innerWidth;
                        this.params.slidesPerView =  that._properties.multi ?getSlidesNumber(width,carouselFlag) : 1;
                        this.params.slidesPerGroup =  that._properties.multi ?getSlidesNumber(width,carouselFlag) : 1;
                        this.update();
                    }
                }
            })
            if(!that._properties.autopauseDisabled && that._properties.autoplay){
                sw.el.onmouseover = function(){
                    sw.autoplay.stop();
                }
                sw.el.onmouseout = function(){
                    sw.autoplay.start();
                }
            }

            if(carouselFlag !='kv-carousel' && carouselFlag !='common-carousel'){
                config.element.querySelectorAll(".swiper-slide").forEach(v=>{
                    v.addEventListener("click",function(e){
                        e.stopPropagation();
                        e.currentTarget.querySelector("a").click();
                    })
                })
            }


            if(config.element.querySelector(".swiper-button-prev") != null){
                /*config.element.querySelector(".swiper-button-prev").addEventListener("click",function(){
                    sw.slidePrev();
                })*/

                let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
                let mutationObserver = new MutationObserver(function(mutations){
                    mutations.forEach(function(mutation){
                        if(mutation.target.classList.contains("swiper-button-disabled")){
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-prev-clone").classList.add("swiper-button-disabled");
                        }else{
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-prev-clone").classList.remove("swiper-button-disabled");
                        }

                        if(mutation.target.classList.contains("swiper-button-lock")){
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-prev-clone").style.display = "none"
                        }else{
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-prev-clone").style.display = "block";
                        }
                    })
                })
                mutationObserver.observe(config.element.querySelectorAll(".swiper-button-prev")[0],{
                    attributeFilter: ['class']
                })
            }
            if(config.element.querySelector(".swiper-button-next") != null){
                /*config.element.querySelector(".swiper-button-next").addEventListener("click",function(){
                    sw.slideNext();
                })*/

                let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
                let mutationObserver = new MutationObserver(function(mutations){
                    mutations.forEach(function(mutation){
                        if(mutation.target.classList.contains("swiper-button-disabled")){
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-next-clone").classList.add("swiper-button-disabled");
                        }else{
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-next-clone").classList.remove("swiper-button-disabled");
                        }

                        if(mutation.target.classList.contains("swiper-button-lock")){
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-next-clone").style.display = "none"
                        }else{
                            mutation.target.parentElement.parentElement.querySelector(".swiper-button-next-clone").style.display = "block";
                        }
                    })
                })
                mutationObserver.observe(config.element.querySelectorAll(".swiper-button-next")[0],{
                    attributeFilter: ['class']
                })
            }

            if(config.element.parentElement.querySelector(".swiper-button-prev-clone") != null){
                config.element.parentElement.querySelector(".swiper-button-prev-clone").style.display =
                    config.element.parentElement.querySelector(".swiper-button-prev").classList.contains("swiper-button-lock") ?
                        "none" : "block";
                config.element.parentElement.querySelector(".swiper-button-prev-clone").addEventListener("click",function(){
                    config.element.querySelector(".swiper-button-prev").click();
                })
            }
            if(config.element.parentElement.querySelector(".swiper-button-next-clone") != null){
                config.element.parentElement.querySelector(".swiper-button-next-clone").style.display =
                    config.element.parentElement.querySelector(".swiper-button-next").classList.contains("swiper-button-lock") ?
                        "none" : "block";
                config.element.parentElement.querySelector(".swiper-button-next-clone").addEventListener("click",function(){
                    config.element.querySelector(".swiper-button-next").click();
                })
            }

            /*if(config.element.querySelector(".swiper-pagination") != null){
                config.element.querySelectorAll(".swiper-pagination-bullet").forEach((v,i)=>{
                    v.addEventListener("click",function(){
                        sw.slideTo(i);
                    })
                })
            }*/

            if (window.Granite && window.Granite.author && window.Granite.author.MessageChannel) {
                /*
                 * Editor message handling:
                 * - subscribe to "cmp.panelcontainer" message requests sent by the editor frame
                 * - check that the message data panel container type is correct and that the id (path) matches this specific Carousel component
                 * - if so, route the "navigate" operation to enact a navigation of the Carousel based on index data
                 */
                new window.Granite.author.MessageChannel("cqauthor", window).subscribeRequestMessage("cmp.panelcontainer", function(message) {
                    if (message.data && message.data.type === "cmp-carousel" && message.data.id === that._elements.self.dataset["cmpPanelcontainerId"]) {
                        if (message.data.operation === "navigate") {
                            navigate(message.data.index);
                        }
                    }
                });
            }
        }

        function getSlidesNumber(width,type){
            let number = 1;
            let data = slidesNumber.filter(v=>v.type == type);
            if(data.length > 0){
                data[0].screen.forEach(v=>{
                    if(width > v.size){
                        number = v.number;
                    }
                })
            }
            return number;
        }

        /**
         * Caches the Carousel elements as defined via the {@code data-carousel-hook="ELEMENT_NAME"} markup API
         *
         * @private
         * @param {HTMLElement} wrapper The Carousel wrapper element
         */
        function cacheElements(wrapper) {
            that._elements = {};
            that._elements.self = wrapper;
            var hooks = that._elements.self.querySelectorAll("[data-" + NS + "-hook-" + IS + "]");

            for (var i = 0; i < hooks.length; i++) {
                var hook = hooks[i];
                var capitalized = IS;
                capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
                var key = hook.dataset[NS + "Hook" + capitalized];
                if (that._elements[key]) {
                    if (!Array.isArray(that._elements[key])) {
                        var tmp = that._elements[key];
                        that._elements[key] = [tmp];
                    }
                    that._elements[key].push(hook);
                } else {
                    that._elements[key] = hook;
                }
            }
        }

        /**
         * Sets up properties for the Carousel based on the passed options.
         *
         * @private
         * @param {Object} options The Carousel options
         */
        function setupProperties(options) {

            that._properties = {};

            for (var key in properties) {
                if (properties.hasOwnProperty(key)) {
                    var property = properties[key];
                    var value = null;
                    if (options && options[key] != null) {
                        value = options[key];

                        // transform the provided option
                        if (property && typeof property.transform === "function") {
                            value = property.transform(value);
                        }
                    }

                    if (value === null) {
                        // value still null, take the property default
                        value = properties[key]["default"];
                    }
                    if(key=='multi'){ 
                        value = options[key]; 
                        if(value=='false'){ value = false; }
                    }
                    that._properties[key] = value;
                }
            }
        }
    }

    /**
     * Reads options data from the Carousel wrapper element, defined via {@code data-cmp-*} data attributes
     *
     * @private
     * @param {HTMLElement} element The Carousel element to read options data from
     * @returns {Object} The options read from the component data attributes
     */
    function readData(element) {
        var data = element.dataset;
        var options = [];
        var capitalized = IS;
        capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
        var reserved = ["is", "hook" + capitalized];

        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                var value = data[key];

                if (key.indexOf(NS) === 0) {
                    key = key.slice(NS.length);
                    key = key.charAt(0).toLowerCase() + key.substring(1);

                    if (reserved.indexOf(key) === -1) {
                        options[key] = value;
                    }
                }
            }
        }

        return options;
    }

    /**
     * Document ready handler and DOM mutation observers. Initializes Carousel components as necessary.
     *
     * @private
     */
    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new Carousel({ element: elements[i], options: readData(elements[i]) });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new Carousel({ element: element, options: readData(element) });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
        let width = window.innerWidth;
        changeKVImage(width)
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

    window.addEventListener("resize",function(e){
        let win = e.currentTarget;
        let width = win.screen.width;
        changeKVImage(width)
    })

    function changeKVImage(width){
        if(width > 1024){
            document.querySelectorAll(".kvitem>a").forEach(v=>{
                v.style.backgroundImage = 'url("'+(v.getAttribute("image"))+'")';
            })
        }else{
            document.querySelectorAll(".kvitem>a").forEach(v=>{
                v.style.backgroundImage = 'url("'+(v.getAttribute("mobileimage"))+'")';
            })
        }
    }
}());
